'use client';

import {useRouter} from 'next/navigation';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import fetcher from './fetcher';

const useLogout = () => {
  const router = useRouter();
  const queryClient = useQueryClient();

  const {mutateAsync: logout} = useMutation({
    mutationFn: () => fetcher({url: '/api/auth/logout', options: {method: 'POST'}}),
    onSuccess: () => {
      queryClient.clear();
      router.push('/');
      router.refresh();
    }
  });

  return logout;
};

export default useLogout;
