export const loginErrors = {
  emailIsNotRegistered: 'There is no account with this email.'
};

export const signupErrors = {
  emailAlreadyRegistered: 'Account with this email already exists.'
};

export const authErrors = {
  sessionBlocked: 'This session was blocked, please generate a new code.',
  sessionExpired: 'This session expired. Please generate a new code.',
  wrongCode: 'Wrong code! You can try again.'
};
