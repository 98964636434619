import {useQuery} from '@tanstack/react-query';
import fetcher from 'src/utils/fetcher';
import useSession from './useSession';

const useUser = () => {
  const {data, isLoading} = useQuery({
    queryKey: ['user'],
    queryFn: () => fetcher<User>({url: '/api/auth/user'})
  });

  const {session} = useSession(!data);

  return {user: data || session, isLoading};
};

export default useUser;
