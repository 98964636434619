type PlausibleEventOptions = {
  callback?: Function;
  props?: Object;
};

const recordEvent = (eventName: string, options?: PlausibleEventOptions) => {
  if (typeof window !== 'undefined' && window.plausible) window.plausible(eventName, options);
};

export default recordEvent;
