'use client';

import {twJoin} from 'tailwind-merge';
import {useMemo, useState} from 'react';
import {getSignedInContextMenuItems} from 'src/utils/getNavMenuItems';
import SkipRenderOnClient from '../common/SkipRenderOnClient';
import Dropdown, {type DropdownMenuItem} from '../Dropdown';
import {menu4Line, userSmileLine} from 'src/utils/icons';
import useLogout from 'src/utils/useLogout';
import AuthModal from '../auth/AuthModal';
import ThemeToggle from './ThemeToggle';
import useUser from 'src/hooks/useUser';
import BaseButton from '../BaseButton';
import RemixIcon from '../RemixIcon';

const navButtonClassName = 'flex-col text-sm h-14 px-4 max-[700px]:px-2 rounded-lg font-bold';

export type NavButtonProps = {
  label: string;
  labelComponent?: React.ReactNode;
  icon: () => JSX.Element;
  href?: string;
  isVisible: boolean;
  onPress?: () => void;
};

type Props = {
  items: NavButtonProps[];
};

const NavButtons = ({items}: Props) => {
  const {user, isLoading} = useUser();
  const logout = useLogout();

  const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const userContextMenuItems = useMemo(
    () => [
      {component: <ThemeToggle key="theme-toggle" />},
      'separator' as const,
      ...getSignedInContextMenuItems(Boolean(user?.isAdmin), logout)
    ],
    [logout, user]
  );

  const mobileContextMenuItems: DropdownMenuItem[] = useMemo(
    () => [
      {component: <ThemeToggle key="theme-toggle" />},
      'separator' as const,
      ...items.map((navButton) => ({
        key: navButton.label,
        text: navButton.label,
        onAction: navButton.onPress,
        icon: navButton.icon,
        href: navButton.href
      })),
      ...(user ? getSignedInContextMenuItems(Boolean(user?.isAdmin), logout) : [])
    ],
    [user, items, setIsJoinModalOpen, setIsLoginModalOpen]
  );

  return (
    <>
      <SkipRenderOnClient
        minWidth={1050}
        className={twJoin(
          'flex justify-center max-[1050px]:hidden transition-opacity',
          isLoading ? 'opacity-0' : 'opacity-100'
        )}
      >
        {items
          .filter((b) => b.isVisible)
          .map((navButton) => {
            return (
              <BaseButton
                key={navButton.label}
                prefetch={false}
                color="var(--dynamic-purple)"
                className={navButtonClassName}
                buttonVariant="text"
                href={navButton.href}
                onPress={navButton.onPress}
              >
                <RemixIcon icon={navButton.icon} color="var(--dynamic-purple)" />

                {navButton.labelComponent ? navButton.labelComponent : navButton.label}
              </BaseButton>
            );
          })}

        {user ? (
          <Dropdown items={userContextMenuItems}>
            <BaseButton color="var(--dynamic-purple)" className={navButtonClassName} buttonVariant="text">
              <RemixIcon icon={userSmileLine} color="var(--dynamic-purple)" />
              <div className="max-w-[80px] relative">
                <span className="block truncate">{user.name}</span>
              </div>
            </BaseButton>
          </Dropdown>
        ) : (
          <Dropdown items={[{component: <ThemeToggle key="theme-toggle" className="mb-0" />}]}>
            <BaseButton color="var(--dynamic-purple)" className={navButtonClassName} buttonVariant="text">
              <RemixIcon icon={menu4Line} color="var(--dynamic-purple)" />
              Menu
            </BaseButton>
          </Dropdown>
        )}
      </SkipRenderOnClient>

      <SkipRenderOnClient
        maxWidth={1050}
        className={twJoin(
          'justify-center hidden max-[1050px]:flex transition-opacity',
          isLoading ? 'opacity-0' : 'opacity-100'
        )}
      >
        <Dropdown items={mobileContextMenuItems}>
          <BaseButton color="var(--dynamic-purple)" className={navButtonClassName} buttonVariant="text">
            <RemixIcon icon={menu4Line} color="var(--dynamic-purple)" />
            Menu
          </BaseButton>
        </Dropdown>
      </SkipRenderOnClient>

      {user ? null : (
        <>
          <AuthModal initialView="join" isOpen={isJoinModalOpen} setIsOpen={setIsJoinModalOpen} />
          <AuthModal initialView="login" isOpen={isLoginModalOpen} setIsOpen={setIsLoginModalOpen} />
        </>
      )}
    </>
  );
};

export default NavButtons;
