import {atom, useAtom} from 'jotai';
import {useEffect, useRef} from 'react';

export const windowScrolledState = atom<boolean>(false);

const useIsScrolled = (scrolledThreshold = 10) => {
  const isScrolledRef = useRef(false);
  const [isScrolled, setScrolled] = useAtom(windowScrolledState);

  useEffect(() => {
    const reportScroll = () => {
      if (window.scrollY > scrolledThreshold && !isScrolledRef.current) {
        isScrolledRef.current = true;
        setScrolled(true);
      } else if (window.scrollY <= scrolledThreshold && isScrolledRef.current) {
        isScrolledRef.current = false;
        setScrolled(false);
      }
    };

    reportScroll();

    window.addEventListener('scroll', reportScroll, {passive: true});

    return () => window.removeEventListener('scroll', reportScroll);
  }, [scrolledThreshold]);

  return isScrolled;
};

export default useIsScrolled;
