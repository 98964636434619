import {useTheme} from 'next-themes';

const useDarkMode = () => {
  const {setTheme, resolvedTheme, theme} = useTheme();

  return {
    isDark: resolvedTheme === 'dark',
    theme: theme as 'dark' | 'light' | 'system',
    resolvedTheme,
    setTheme,
    isLoadingTheme: !resolvedTheme || resolvedTheme === 'system',
    isSystem: theme === 'system'
  };
};

export default useDarkMode;
