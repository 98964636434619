import {startTransition, useEffect, useRef, useState} from 'react';

type Options = {
  delayMs?: number;
};

const useMounted = ({delayMs}: Options = {delayMs: 0}) => {
  const isMounted = useRef(false);

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (isMounted.current) return;

    const timeout = setTimeout(() => {
      startTransition(() => setMounted(true));
    }, delayMs);

    return () => clearTimeout(timeout);
  }, []);

  return mounted;
};

export default useMounted;
