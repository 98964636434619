'use client';

import {twJoin} from 'tailwind-merge';
import useIsScrolled from 'src/hooks/useIsScrolled';

const NavBarDynamicShadow = () => {
  const isScrolled = useIsScrolled();

  return (
    <div
      className={twJoin([
        'h-px w-full absolute bg-[rgb(0_0_0_/_0.06)] dark:bg-[rgb(245_225_255_/_0.06)] -bottom-px transition-opacity duration-500',
        isScrolled ? 'opacity-100' : 'opacity-0'
      ])}
    />
  );
};

export default NavBarDynamicShadow;
