'use client';

import {useAtomValue} from 'jotai';
import {twMerge} from 'tailwind-merge';
import {searchUsedState} from 'src/hooks/useSearch';
import Search from './Search';

const NavBarSearch = () => {
  const isSearchUsed = useAtomValue(searchUsedState);

  return (
    <Search
      className={twMerge(
        'mx-auto w-[540px] max-[1200px]:w-[400px] max-[700px]:w-[calc(100%-128px)] max-[700px]:left-[56px] top-[18px]',
        isSearchUsed
          ? 'max-[600px]:left-[16px] max-[600px]:w-[calc(100%-32px)]'
          : 'focus-within:max-[600px]:left-[16px] focus-within:max-[600px]:w-[calc(100%-32px)]'
      )}
    />
  );
};

export default NavBarSearch;
