'use client';

import {useId, useRef} from 'react';
import useWidthBreakpoints from 'src/utils/useWidthBreakpoints';

const useIsFirstRender = (): boolean => {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return false;
};

/**
 * `SkipRenderOnClient` is a SSR performance hack that allows you to
 * conditionally render components on the client-side, bypassing hydration,
 * without introducing hydration mismatches.
 *
 * ```tsx
 * <SkipRenderOnClient shouldRenderOnClient={() => window.innerWidth <= 500}>
 *   <MyComponent />
 * </SkipRenderOnClient>
 * ```
 *
 * Context:
 *
 * When using React's server-side rendering, we often need to render components
 * on the server even if they are conditional on the client e.g. hidden based on
 * window width.
 *
 * However, in order for hydration to succeed, the first client render must
 * match the DOM generated from the HTML returned by the server. This means the
 * component must be rendered again during the first client render.
 *
 * Hydration is expensive, so we really don't want to pay that penalty only for
 * the element to be hidden or removed immediately afterwards.
 *
 * `SkipRenderOnClient` conditionally skips hydrating children by removing them
 * from the DOM _before the first client render_. Removing them before ensures
 * hydration is successful.
 */

const getShouldBeDisplayed = (activeBreakpoint: number) => {
  return activeBreakpoint === 1;
};

type Props = {
  children: React.ReactNode;
  maxWidth?: number;
  minWidth?: number;
  className?: string;
};

const SkipRenderOnClient = ({children, minWidth = 0, maxWidth = 100000, className}: Props) => {
  const id = useId();
  const isClient = typeof window !== 'undefined';
  const isFirstRender = useIsFirstRender();

  const activeBreakpoint = useWidthBreakpoints([minWidth, maxWidth]);

  const shouldRenderOnClient = isClient && getShouldBeDisplayed(activeBreakpoint);

  if (isClient && isFirstRender && shouldRenderOnClient === false) {
    const el = document.getElementById(id);

    if (el !== null) {
      el.innerHTML = '';
    }
  }

  const shouldRender = isClient ? shouldRenderOnClient : true;

  return (
    <div id={id} className={className}>
      {shouldRender && children}
    </div>
  );
};

export default SkipRenderOnClient;
