import {useQuery} from '@tanstack/react-query';
import fetcher from 'src/utils/fetcher';

const useSession = (enabled = true) => {
  const {data, isLoading} = useQuery({
    queryKey: ['ironSession'],
    queryFn: () => fetcher<IronSession>({url: '/api/auth/me'}),
    enabled
  });

  return {session: data, isLoading};
};

export default useSession;
